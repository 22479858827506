import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

export let theme = createMuiTheme({
  palette: {
    primary: {
      light: "#79B4D9",
      main: "#1F82C0",
      dark: "#005ab8",
    },
    secondary: {
      light: "#66BFAC",
      main: "#009374",
      dark: "#009374",
    },
  },
  typography: {
    fontFamily:
      "'FrutigerLTW02','Helvetica Neue','Helvetica','Arial', sans-serif",
  },
});
theme = responsiveFontSizes(theme);
