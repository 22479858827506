import { Grid, Hidden } from "@material-ui/core";
import React, { Component } from "react";

class ContentContainer extends Component {
  state = {};
  render() {
    return (
      <Hidden xsDown>
        <Grid item sm={1} lg={2} xl={3} />
      </Hidden>
    );
  }
}

export default ContentContainer;
