import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Image from "../../media/laptop.jpg";
import ImageBox from "../ImageBox";
import ContentContainer from "../layout/ContentContainer";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingBottom: theme.spacing(15),
  },
  imageBox: {
    position: "relative",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    height: "20em",
  },
}));

function App() {
  const classes = useStyles();
  return (
    <>
      <ImageBox />
      <section className={classes.section}>
        <Grid container spacing={2}>
          <ContentContainer />
          <Grid item xs={12} sm={10} md={5} lg={4} xl={3}>
            <Typography>
              Pseudorandom Generators (PRGs) play an important role in security
              of systems and cryptographic mechanisms. Yet, there is a long
              history of vulnerabilities in practical PRGs. Significant efforts
              in the theoretical and practical research communities are invested
              to improve the security of PRGs, to identify faults in entropy
              sources, and to detect vulnerabilities allowing attacks against
              the PRGs. In this work we take an alternative approach at the
              pseudo-randomness generation problem. We design and implement
              Network Pseudo-randomness Collector (NPC) which collects
              pseudorandom strings from servers in the Internet. NPC does not
              require cooperation nor synchronisation of those servers. NPC is
              easy to use and integrate into the existing systems. We analyse
              the security of NPC and show how it addresses the main factors
              behind the vulnerabilities in current PRGs.
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item md={5} lg={4} xl={3}>
              <Paper
                className={classes.imageBox}
                style={{ backgroundImage: `url(${Image})` }}
                elevation={0}
                square
              >
                {<img style={{ display: "none" }} src={Image} alt="Laptop" />}
              </Paper>
            </Grid>
          </Hidden>
          <ContentContainer />
        </Grid>
      </section>
    </>
  );
}

export default App;
