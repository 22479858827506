import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Close from "@material-ui/icons/Close";
import Menu from "@material-ui/icons/Menu";
import { Link } from "@reach/router";
import React from "react";
import Logo from "../../media/sit-logo.svg";

const useStyles = makeStyles((theme) => ({
  border: {
    borderBottom: "1px solid #C7CACC",
  },
  container: {
    marginTop: 40,
    marginBottom: 20,
  },
  listDesktop: {
    display: "flex",
    flexDirection: "row",
    padding: 0,
  },
  logo: {
    maxHeight: "4em",
    maxWidth: "15em",
  },
  links: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  button: {
    fontWeight: "200",
    borderBottom: "4px solid transparent",
    borderRadius: 0,

    "&:hover, &:focus": {
      backgroundColor: "transparent",
      borderBottom: "4px solid #1F82C0",
    },
  },
  link: {
    textDecoration: "none",
    color: "#000000",
  },
  listItem: {
    "&:hover, &:active": {
      backgroundColor: "transparent",
    },
  },
  drawer: {
    minWidth: 260,
  },
  close: {
    justifyContent: "flex-end",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDrawerClose = () => {
    if (mobileOpen) {
      setMobileOpen(!mobileOpen);
    }
  };
  const items = () => (
    <>
      <ListItem className={classes.listItem}>
        <Link to="/" className={classes.link}>
          <Button
            disableRipple={true}
            className={classes.button}
            onClick={handleDrawerClose}
          >
            Home
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/publications" className={classes.link}>
          <Button
            disableRipple={true}
            className={classes.button}
            onClick={handleDrawerClose}
          >
            Publications
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/team" className={classes.link}>
          <Button
            disableRipple={true}
            className={classes.button}
            onClick={handleDrawerClose}
          >
            Team
          </Button>
        </Link>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Link to="/tool" className={classes.link}>
          <Button
            disableRipple={true}
            className={classes.button}
            onClick={handleDrawerClose}
          >
            Tool
          </Button>
        </Link>
      </ListItem>
    </>
  );

  return (
    <Box className={classes.border}>
      <AppBar color="transparent" elevation={0} position="static">
        <Toolbar className={classes.toolbar}>
          <Grid container spacing={2} className={classes.container}>
            <Hidden xsDown>
              <Grid item sm={1} lg={2} xl={3} />
            </Hidden>
            <Grid
              item
              xs={6}
              sm={5}
              lg={3}
              xl={2}
              className="classes.logoBackground"
            >
              <Link to="/">
                <img src={Logo} alt="logo" className={classes.logo} />
              </Link>
            </Grid>
            <Grid item xs={6} sm={5} lg={5} xl={4} className={classes.links}>
              <Hidden smDown implementation="css">
                <List className={classes.listDesktop} component="nav">
                  {items()}
                </List>
              </Hidden>
              <Hidden mdUp>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Menu />
                </IconButton>
              </Hidden>
            </Grid>
            <Hidden xsDown>
              <Grid item sm={1} lg={2} xl={3} />
            </Hidden>
          </Grid>
        </Toolbar>
        <Hidden mdUp implementation="js">
          <Drawer
            variant="temporary"
            anchor={"right"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
          >
            <List button component="nav">
              <ListItem className={classes.close}>
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerToggle}
                >
                  <Close />
                </IconButton>
              </ListItem>
              <div className={classes.drawer}>{items()}</div>
            </List>
          </Drawer>
        </Hidden>
      </AppBar>
    </Box>
  );
}
