import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";
import Image from "../media/random_hero.jpg";

const useStyles = makeStyles((theme) => ({
  heroImageBox: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.3)",
    borderRadius: theme.spacing(0.5),
  },
  heroImageBoxContent: {
    position: "relative",
    textAlign: "center",
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(20),
      paddingBottom: theme.spacing(20),
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.spacing(25),
      paddingBottom: theme.spacing(25),
    },
  },
}));

export default function ImageBox() {
  const classes = useStyles();
  return (
    <Grid container spacing={2}>
      <Hidden mdDown>
        <Grid item lg={1} xl={2} />
      </Hidden>
      <Grid item xs={12} lg={10} xl={8}>
        <Paper
          className={classes.heroImageBox}
          style={{ backgroundImage: `url(${Image})` }}
          elevation={2}
        >
          {<img style={{ display: "none" }} src={Image} alt="Bild" />}
          <div className={classes.overlay} />
          <div className={classes.heroImageBoxContent}>
            <Typography component="h1" variant="h2" color="inherit">
              Randomness
            </Typography>
          </div>
        </Paper>
      </Grid>
      <Hidden mdDown>
        <Grid item lg={1} xl={2} />
      </Hidden>
    </Grid>
  );
}
