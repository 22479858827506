import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import ContentContainer from "./ContentContainer";

const useStyles = makeStyles((theme) => ({
  border: {
    borderTop: "1px solid #C7CACC",
  },
  link: {
    marginRight: "2em",
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();
  return (
    <Box className={classes.border}>
      <AppBar
        //style={{ backgroundColor: "#C7CACC" }}
        color="transparent"
        elevation={0}
        position="static"
      >
        <Toolbar className={classes.toolbar}>
          <ContentContainer />
          <Grid item xs={12} sm={10} lg={8} xl={6}>
            <Link
              href="https://www.sit.fraunhofer.de/en/imprint-haya-shulman/"
              variant="body1"
              color="secondary"
              underline="none"
              className={classes.link}
            >
              Imprint
            </Link>
            <Link
              href="https://www.sit.fraunhofer.de/en/privacy-statement/"
              variant="body1"
              color="secondary"
              underline="none"
            >
              Privacy Statement
            </Link>
          </Grid>
          <ContentContainer />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
