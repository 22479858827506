import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import PageTitle1 from "../base/PageTitle1";
import ContentContainer from "../layout/ContentContainer";
import ToolCard from "../ToolCard";

const useStyles = makeStyles((theme) => ({
  spaceBottom: {
    marginBottom: theme.spacing(2),
  },
}));

const maxBits = 8192;
let minBits = 256;
const toolCardElements = [];
for (let i = minBits; i <= maxBits; i *= 2) {
  toolCardElements.push(<ToolCard bits={i} key={i} />);
}

function App() {
  const classes = useStyles();
  return (
    <>
      <Grid container spacing={2}>
        <ContentContainer />
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <PageTitle1 title="Tool" />
        </Grid>
        <ContentContainer />
        <ContentContainer />
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <div className={classes.spaceBottom}>
            <Alert severity="info" className={classes.root}>
              Randomness will be collected on request! DO NOT ABUSE!
            </Alert>
          </div>
          {toolCardElements}
        </Grid>
        <ContentContainer />
      </Grid>
    </>
  );
}

export default App;
