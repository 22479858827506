import { Grid } from "@material-ui/core";
import React from "react";
import PageTitle1 from "../base/PageTitle1";
import ContentContainer from "../layout/ContentContainer";
import PersonCard from "../PersonCard";

function App() {
  return (
    <>
      <Grid container spacing={2}>
        <ContentContainer />
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <PageTitle1 title="Team" />
        </Grid>
        <ContentContainer />
        <ContentContainer />
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <Grid container spacing={2}>
            <PersonCard
              title="Markus Brandt"
              subtitle="Fraunhofer SIT, TU Darmstadt"
              mail="markus.brandt@sit.fraunhofer.de"
            />
            <PersonCard
              title="Haya Shulman"
              subtitle="Fraunhofer SIT, TU Darmstadt"
              mail="haya.shulman@sit.fraunhofer.de"
            />
            <PersonCard
              title="Michael Waidner"
              subtitle="Fraunhofer SIT, TU Darmstadt"
              mail="michael.waidner@sit.fraunhofer.de"
            />
          </Grid>
        </Grid>
        <ContentContainer />
      </Grid>
    </>
  );
}

export default App;
