import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GetApp from "@material-ui/icons/GetApp";
import React, { Component } from "react";

const useStyles = (theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  title: {
    marginTop: theme.spacing(1),
  },
});

class DownloadCard extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root} variant="outlined">
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.title}>
            {this.props.title}
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            href={
              process.env.PUBLIC_URL + "/downloads/" + this.props.documentName
            }
            size="small"
            startIcon={<GetApp />}
            color="primary"
            download
          >
            Download
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(useStyles)(DownloadCard);
