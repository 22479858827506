import { Grid } from "@material-ui/core";
import React from "react";
import PageTitle1 from "../base/PageTitle1";
import PageTitle2 from "../base/PageTitle2";
import DownloadCard from "../DownloadCard";
import ContentContainer from "../layout/ContentContainer";
import PublicationCard from "../PublicationCard";

function App() {
  return (
    <section>
      <Grid container spacing={2}>
        <ContentContainer />
        <Grid item xs={12} sm={10} lg={8} xl={6}>
          <PageTitle1 title="Publications" />
          <PublicationCard
            authors="Markus Brandt, Haya Shulman, Michael Waider"
            title="Internet As a Source of Randomness"
            subtitle="HotNets '18: Proceedings of the 17th ACM Workshop on Hot Topics in
            Networks, Redmond, WA, USA, November 2018"
            documentName="HotNets2018-brandt.pdf"
          />
          <PublicationCard
            authors="Markus Brandt, Haya Shulman, Michael Waider"
            title="Internet As a Source of Randomness"
            subtitle="crypto day matters 30. Bonn: Gesellschaft für Informatik e.V. /
                FG KRYPTO, Berlin, Germany, March 2019, Berlin"
            documentName="cyptoday30-brandt.pdf"
          />
          <PageTitle2 title="Slides" />
          <DownloadCard
            title="HotNets '18"
            documentName="HotNets2018-brandt_slides.pdf"
          />
          <DownloadCard
            title="crypto day matters 30"
            documentName="cyptoday30-brandt_slides.pdf"
          />
        </Grid>
        <ContentContainer />
      </Grid>
    </section>
  );
}

export default App;
