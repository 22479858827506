import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import React from "react";

const useStyles = makeStyles((theme) => ({
  centerMessage: {
    marginTop: "25vh",
  },
}));

export default function NotFound() {
  const classes = useStyles();
  return (
    <>
      <Box textAlign="center" m={1} className={classes.centerMessage}>
        <Typography variant="h2" color="secondary" component="h1">
          404
        </Typography>
        <Typography variant="h3" component="h2">
          The page you’re looking
          <br /> for can’t be found.
        </Typography>
      </Box>
    </>
  );
}
