import {
  Button,
  Card,
  CardActions,
  CardContent,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import GetApp from "@material-ui/icons/GetApp";
import React, { Component } from "react";

const useStyles = (theme) => ({
  root: {
    minWidth: 275,
    marginBottom: theme.spacing(2),
  },
  authors: {
    fontSize: 14,
  },
  title: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.75),
  },
});

class PublicationCard extends Component {
  state = {};

  render() {
    const { classes } = this.props;
    return (
      <Card className={classes.root} variant="outlined">
        {this.state.showComponent ? <LinearProgress /> : null}
        <CardContent>
          <Typography variant="h5" component="h2" className={classes.title}>
            {this.props.bits} bits
          </Typography>
        </CardContent>
        <CardActions>
          <Button
            href={
              "https://randomlab.cad.sit.fraunhofer.de/get/" + this.props.bits
            }
            size="small"
            startIcon={<GetApp />}
            color="primary"
            download
          >
            Download
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(useStyles)(PublicationCard);
