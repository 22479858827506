import { Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";

const useStyles = (theme) => ({
  heading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
});

class PageTitle1 extends Component {
  state = {};
  render() {
    const { classes } = this.props;

    return (
      <Typography
        variant="h4"
        component="h1"
        color="primary"
        className={classes.heading}
      >
        {this.props.title}
      </Typography>
    );
  }
}

export default withStyles(useStyles)(PageTitle1);
