import { ThemeProvider } from "@material-ui/core/styles";
import { Router } from "@reach/router";
import React from "react";
import ReactDOM from "react-dom";
import { theme } from "./components/base/Theme";
import AppBar from "./components/layout/AppBar";
import Footer from "./components/layout/Footer";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import Publications from "./components/pages/Publications";
import Team from "./components/pages/Team";
import Tool from "./components/pages/Tool";
import "./index.css";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <div className="Site">
      <div className="Site-content">
        <AppBar />
        <div style={{ padding: 25 }}>
          <Router primary={false}>
            <NotFound default />
            <Home path="/" />
            <Publications path="/publications" />
            <Team path="/team" />
            <Tool path="/tool" />
          </Router>
        </div>
      </div>
    </div>
    <Footer />
  </ThemeProvider>,
  document.getElementById("root")
);
