import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Mail from "@material-ui/icons/Mail";
import React, { Component } from "react";

const useStyles = (theme) => ({
  title: {
    marginTop: theme.spacing(1),
  },
});

class DownloadCard extends Component {
  state = {};
  render() {
    const { classes } = this.props;
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h5" component="h2" className={classes.title}>
              {this.props.title}
            </Typography>
            <Typography variant="body2" component="p">
              {this.props.subtitle}
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              href={"mailto:" + this.props.mail}
              size="small"
              startIcon={<Mail />}
              color="primary"
              download
            >
              E-Mail
            </Button>
          </CardActions>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(useStyles)(DownloadCard);
